import React from "react";

function Consultas(props) {

    const consultas = () => {
        let bodyTable = '';
        if (props.result.consultas_anteriores_scpc.hasOwnProperty('registros')) {
            const registros = props.result.consultas_anteriores_scpc.registros;
            if (Array.isArray(registros)) {
                bodyTable = registros.map((registro, index) => (
                    <tr key={index}>
                        <td>{registro.data}</td>
                        <td>{registro.informante}</td>
                        <td>{registro.tipo}</td>
                        <td>{registro.descricao}</td>
                    </tr>
                ))
            } else {
                bodyTable = (
                    <tr>
                        <td>{registros.data}</td>
                        <td>{registros.informante}</td>
                        <td>{registros.tipo}</td>
                        <td>{registros.descricao}</td>
                    </tr>
                )
            }
        } else {
            bodyTable = (
                <tr>
                    <td className="text-center" colSpan={4}>Não há registros.</td>
                </tr>
            )
        }

        return bodyTable;
    }

    const BodyTable = () => consultas();

    return (
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                <tr>
                    <th>Data</th>
                    <th>Informante</th>
                    <th>Tipo</th>
                    <th>Descrição</th>
                </tr>
                </thead>
                <tbody><BodyTable/></tbody>
            </table>
        </div>
    );
}

export default Consultas;