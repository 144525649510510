import React from "react";

function Debitos(props) {

    const debitos = () => {
        let bodyTable = '';
        if (props.result.debitos.hasOwnProperty('ocorrencia')) {
            const registrosScpc = props.result.debitos.ocorrencia;
            if (Array.isArray(registrosScpc)) {
                bodyTable = registrosScpc.map((registro, index) => (
                    <tr key={index}>
                        <td>{registro.cidade}</td>
                        <td>{registro.condicao}</td>
                        <td>{registro.consulente}</td>
                        <td>{registro.contrato}</td>
                        <td>{registro.data_disponibilizacao}</td>
                        <td>{registro.data_ocorrencia}</td>
                        <td>{registro.informante}</td>
                        <td>{registro.situacao}</td>
                        <td>{registro.tipo}</td>
                        <td>{registro.tipo_cod}</td>
                        <td>{registro.uf}</td>
                        <td>{registro.valorn}</td>
                    </tr>
                ))
            } else {
                bodyTable = (
                    <tr>
                        <td>{registrosScpc.cidade}</td>
                        <td>{registrosScpc.condicao}</td>
                        <td>{registrosScpc.consulente}</td>
                        <td>{registrosScpc.contrato}</td>
                        <td>{registrosScpc.data_disponibilizacao}</td>
                        <td>{registrosScpc.data_ocorrencia}</td>
                        <td>{registrosScpc.informante}</td>
                        <td>{registrosScpc.situacao}</td>
                        <td>{registrosScpc.tipo}</td>
                        <td>{registrosScpc.tipo_cod}</td>
                        <td>{registrosScpc.uf}</td>
                        <td>{registrosScpc.valorn}</td>
                    </tr>
                )
            }
        } else {
            bodyTable = (
                <tr>
                    <td className="text-center" colSpan={11}>Não há registros.</td>
                </tr>
            )
        }

        return bodyTable;
    }

    const BodyTable = () => debitos()

    return (
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                <tr>
                    <th>Cidade</th>
                    <th>Condição</th>
                    <th>Consulente</th>
                    <th>Contrato</th>
                    <th>Disponibilização</th>
                    <th>Ocorrência</th>
                    <th>Informante</th>
                    <th>Situação</th>
                    <th>Tipo</th>
                    <th>Tipo cod.</th>
                    <th>UF</th>
                    <th>Valor</th>
                </tr>
                </thead>
                <tbody><BodyTable/></tbody>
            </table>
        </div>
    );
}

export default Debitos;