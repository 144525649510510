import React from "react";
import Page from "../../components/page";

export default class Home extends React.Component {

    render() {
        return (
            <Page title="Home">
                <div className="row">
                    <div className="col">
                        <div className="card card-primary card-outline card-tabs">
                            <div className="card-body">
                                <p>Seja bem vindo(a) ao Sistema de Pesquisa!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        )
    }
}