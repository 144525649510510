import React from "react";
import InputMask from "react-input-mask";
import Page from "../../components/page";
import Card from "../../components/card";
import AppService from "../../services/app-service";
import {Button, InputGroup, Tab, Tabs} from "react-bootstrap";
import toastr from 'toastr';
import Info from "./info";
import Registros from "./registros";
import Consultas from "./consultas";

export default class PendenciaFinanceiraScpc extends React.Component {

    constructor() {
        super();
        this.appService = new AppService();
    }

    state = {
        cpf: '',
        load: false,
        results: null
    }

    bindState = (event) => {
        const inputName = event.target.name;
        const value = event.target.value;
        this.setState({[inputName]: value});
    }

    onSearch = async (event) => {
        event.preventDefault();
        this.setState({load: true});
        const results = await this.appService.getPendenciaFinanceiraSCPC(this.state.cpf);
        this.setState({load: false});
        if (results) {
            if (results.hasOwnProperty('sintese_cadastral')) {
                this.setState({results: results});
            } else if (results.hasOwnProperty('status') && results.status === 401) {
                toastr.warning(results.mensagem);
                this.props.history.push('/login')
            } else if (results.hasOwnProperty('mensagem')) {
                toastr.warning(results.mensagem);
                this.setState({results: null});
            }
        }
    }

    result = () => {
        if (this.state.results) {
            return (
                <Card>
                    <Tabs defaultActiveKey="info">
                        <Tab eventKey="info" title="INFORMAÇÕES">
                            <h5 className="mb-3 mt-4">Sintese cadastral:</h5>
                            <Info result={this.state.results}/>
                        </Tab>

                        <Tab eventKey="registros" title="DÉBITOS">
                            <h5 className="mb-3 mt-4">Débitos:</h5>
                            <Registros result={this.state.results}/>
                        </Tab>

                        <Tab eventKey="consultas" title="CONSULTAS">
                            <h5 className="mb-3 mt-4">Consultas anteriores SCPC:</h5>
                            <Consultas result={this.state.results}/>
                        </Tab>
                    </Tabs>
                </Card>
            )
        } else {
            return '';
        }
    }

    render() {
        const Results = () => this.result();

        return (
            <Page title="Pendência Financeira SCPC">
                <Card load={this.state.load}>
                    <form onSubmit={this.onSearch}>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <InputGroup className="mb-3">
                                        <InputMask
                                            placeholder="Digite o CPF:"
                                            name="cpf"
                                            value={this.state.cpf}
                                            className="form-control"
                                            onChange={this.bindState}
                                            required
                                            mask="999.999.999-99"/>
                                        <InputGroup.Append>
                                            <Button variant="primary" type="submit">
                                                <i className="fas fa-search"/> Pesquisar
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                    </form>
                </Card>

                <Results/>
            </Page>
        )
    }
}