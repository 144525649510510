import React, {useState} from 'react'
import Page from "../../components/page";
import {Button, InputGroup} from "react-bootstrap";
import InputMask from "react-input-mask";
import Card from "../../components/card";
import AppService from "../../services/app-service";
import toastr from 'toastr'
import moment from 'moment'

export default function LideryPlaca() {

    const [placa, setPlaca] = useState('');
    const [load, setLoad] = useState(false);
    const [associado, setAssociado] = useState({});
    const appService = new AppService();

    const onSearch = async (event) => {
        event.preventDefault();
        setLoad(true);
        const response = await appService.getPlacaLidery(placa);
        if (response?.id) {
            setAssociado(response);
        } else if (response?.mensagem) {
            toastr.warning(response.mensagem);
        } else {
            setAssociado({mensagem: 'Associado não encontrado ou não ativo.'})
        }

        setLoad(false);
    }

    const classTable = (status) => {
        if (status === 'B') {
            return 'table-success';
        } else if (status === 'A') {
            return 'table-warning';
        } else {
            return 'table-default';
        }
    }

    const tipoPagamento = (tipo) => {
        if (tipo === 'B') {
            return 'Boleto';
        } else if (tipo === 'C') {
            return 'Cartão';
        } else if (tipo === 'PF') {
            return 'Paticipação Facilitada';
        } else {
            return tipo;
        }
    }

    const statusPagamento = (status) => {
        if (status === 'B') {
            return 'Baixado';
        } else if (status === 'A') {
            return 'Aberto';
        } else if (status === 'C') {
            return 'Cancelado';
        } else {
            return status;
        }
    }

    return (
        <Page title="Pesquisar Placa">
            <div className="row">
                <div className="col">

                    <Card load={load}>
                        <form onSubmit={onSearch}>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <InputGroup className="mb-3">
                                            <InputMask
                                                className="form-control"
                                                placeholder="Digite a placa:"
                                                onChange={event => setPlaca(event.target.value.toUpperCase())}
                                                value={placa}
                                                required
                                                mask="***-****"/>
                                            <InputGroup.Append>
                                                <Button variant="primary" type="submit">
                                                    <i className="fas fa-search"/> Pesquisar
                                                </Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Card>

                    {associado?.id ? (
                        <Card>
                            <div className="row">
                                <div className="col-sm-6 mb-3">
                                    <b>Nome:</b> {associado.lead.nome}
                                </div>
                                <div className="col-sm-6 mb-6">
                                    <b>CPF:</b> {associado.lead.cpf_cnpj}
                                </div>
                                <div className="col-sm-4">
                                    <b>Celular:</b> {associado.lead.celular}
                                </div>
                                <div className="col-sm-4">
                                    <b>Email:</b> {associado.lead.email}
                                </div>
                                <div className="col-sm-4">
                                    <b>Apólice:</b> {associado.apolice_facilassist_24h}
                                </div>
                                <div className="col-sm-6 mt-3">
                                    <b>Carro:</b> {associado.lead.modelo_desc}
                                </div>
                                <div className="col-sm-3 mt-3">
                                    <b>Ano:</b> {associado.lead.ano_combustivel}
                                </div>
                                <div className="col-sm-3 mt-3">
                                    <b>Preço:</b> {associado.lead.preco}
                                </div>
                                <div className="col-sm-12 mt-3">
                                    <h5>Financeiro:</h5>
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover table-sm">
                                            <thead>
                                            <th>Tipo Pagamento</th>
                                            <th>Data Vencimento</th>
                                            <th>Valor</th>
                                            <th>Data Pagamento</th>
                                            <th>Status</th>
                                            </thead>
                                            <tbody>
                                            {associado.controle_pagamentos.map((pagamento, key) => (
                                                <tr key={key} className={classTable(pagamento.status_pagamento)}>
                                                    <td>{tipoPagamento(pagamento.tipo_pagamento)}</td>
                                                    <td>{moment(pagamento.data_vencimento).format('DD/MM/YYYY')}</td>
                                                    <td>{pagamento.valor}</td>
                                                    <td>{moment(pagamento.data_pagamento).format('DD/MM/YYYY')}</td>
                                                    <td>{statusPagamento(pagamento.status_pagamento)}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    ) : ''}

                    {associado?.mensagem ? (
                        <Card>
                            <div className="text-center">
                                {associado.mensagem}
                            </div>
                        </Card>
                    ) : ''}

                </div>
            </div>
        </Page>
    )
}