import React from "react";

export default function Auth(props) {
    return (
        <div className="login-box">
            <div className="login-logo">
                <a href="#!"><b>Sistema</b>Pesquisa</a>
            </div>

            <div className="card">
                {props.children}
            </div>
        </div>
    )
}