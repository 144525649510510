import React from "react";

export default function Card(props) {
    return (
        <div className="card card-primary card-outline card-tabs">
            <div className="card-body">
                {props.children}
            </div>
            {props.load ? (<div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin"/></div>) : (<></>)}
        </div>
    )
}