import React from "react";

function Info(props) {
    return (
        <>
            <div className="row mb-3">
                <div className="col-12 col-md-4">
                    <b>Nome:</b> {props.result.sintese_cadastral.nome}
                </div>
                <div className="col-12 col-md-4">
                    <b>Documento:</b> {props.result.sintese_cadastral.documento}
                </div>
                <div className="col-12 col-md-4">
                    <b>Nome da mãe:</b> {props.result.sintese_cadastral.mae}
                </div>
                <div className="col-12 col-md-4">
                    <b>Nascimento:</b> {props.result.sintese_cadastral.data_nascimento}
                </div>
                <div className="col-12 col-md-4">
                    <b>Condição:</b> {props.result.sintese_cadastral.condicao}
                </div>
                <div className="col-12 col-md-4">
                    <b>Data Consulta:</b> {props.result.sintese_cadastral.data_consulta}
                </div>
            </div>
        </>
    );
}

export default Info;