import axios from 'axios'

const TOKEN_APP = '_TOKEN_APP';
const AUTH_USER = '_AUTH_USER';

export default class AppService {

    constructor() {
        this.http = axios.create({baseURL: process.env.REACT_APP_API_URL})
        this.token = localStorage.getItem(TOKEN_APP);
        this.http.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
    }

    login = async ({email, password}) => {
        try {
            const response = await this.http.post('oauth/token', {
                grant_type: 'password',
                client_id: process.env.REACT_APP_API_CLIENT_ID,
                client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
                username: email,
                password
            });

            localStorage.setItem(TOKEN_APP, response.data.access_token);
            this.http.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;

            const user = await this.getApiAuthUser();
            localStorage.setItem(AUTH_USER, JSON.stringify(user));
            return {success: true, msg: 'Login realizado com sucesso.', status: 200};
        } catch (e) {
            if (e.response) {
                if (e.response.status === 400)
                    return {success: false, msg: 'Login ou senha inválidos.', status: e.response.status};
                return {success: false, msg: 'Falha interna.', status: e.response.status};
            } else {
                return {success: false, msg: 'Falha interna.', status: 500}
            }
        }
    }

    logout = () => {
        localStorage.removeItem(AUTH_USER);
        localStorage.removeItem(TOKEN_APP);
    }

    isAuthenticate = () => {
        return !!this.token;
    }

    getUser = () => {
        return JSON.parse(localStorage.getItem(AUTH_USER));
    }

    getApiAuthUser = async () => {
        try {
            const response = await this.http.get('api/usuario/usuario-autenticado');
            return response.data;
        } catch (e) {
            console.log(e.response);
        }
    }

    getPendenciaFinanceiraSCPC = async (cpf) => {
        try {
            const response = await this.http.get(`api/grupovec/pendencia-financeira-scpc?documento=${cpf}`);
            return response.data;
        } catch (e) {
            if (e.response.status === 422) {
                return {mensagem: e.response.data.errors.documento[0], status: 422}
            } else if (e.response.status === 401) {
                return {mensagem: 'Sessão expirada', status: 401}
            } else {
                return {mensagem: 'Falha interna.', status: 500}
            }
        }
    }

    getPendenciaFinanceiraSCPCProtestoEstadual = async ({documento, uf, tipo_pessoa}) => {
        try {
            const filtro = `documento=${documento}&uf=${uf}&tipo_pessoa=${tipo_pessoa}`;
            const response = await this.http.get(`api/grupovec/pendencia-financeira-scpc-proteto-estadual?${filtro}`);
            return response.data;
        } catch (e) {
            if (e.response.status === 422) {
                return {mensagem: e.response.data.errors.documento[0], status: 422}
            } else if (e.response.status === 401) {
                return {mensagem: 'Sessão expirada', status: 401}
            } else {
                return {mensagem: 'Falha interna.', status: 500}
            }
        }
    }

    getPlacaLidery = async (placa) => {
        try {
            const filtro = `placa=${placa}`;
            const response = await this.http.get(`api/lidery/placa?${filtro}`);
            return response.data;
        } catch (e) {
            if (e.response.status === 422) {
                return {mensagem: e.response.data.errors.documento[0], status: 422}
            } else if (e.response.status === 401) {
                return {mensagem: 'Sessão expirada', status: 401}
            } else {
                return {mensagem: 'Falha interna.', status: 500}
            }
        }
    }

}