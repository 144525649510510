import React, {Component} from 'react';
import {Button, Tab, Tabs} from 'react-bootstrap';
import toastr from 'toastr'

import InputMask from "react-input-mask";
import Info from "./info";
import Consultas from "./consultas";
import Debitos from "./debitos";
import AppService from "../../services/app-service";
import Card from "../../components/card";
import Page from "../../components/page";

class PendenciaFinanceiraScpcProtestoEstadual extends Component {

    constructor() {
        super();
        this.appService = new AppService();
    }

    state = {
        documento: '',
        uf: '',
        tipo_pessoa: 'J',
        load: false,
        results: null,
        mask: '99.999.999/9999-99'
    }

    bindState = (event) => {
        const inputName = event.target.name;
        const value = event.target.value;
        this.setState({[inputName]: value});
        this.onInputMask();
    }

    onSearch = async (event) => {
        event.preventDefault();
        this.setState({load: true});
        const filtro = {documento: this.state.documento, uf: this.state.uf, tipo_pessoa: this.state.tipo_pessoa};
        const results = await this.appService.getPendenciaFinanceiraSCPCProtestoEstadual(filtro);
        this.setState({load: false});

        if (results) {
            if (results.hasOwnProperty('sintese_cadastral')) {
                this.setState({results: results});
            } else if (results.hasOwnProperty('status') && results.status === 401) {
                toastr.warning(results.mensagem);
                this.props.history.push('/login')
            } else if (results.hasOwnProperty('mensagem')) {
                toastr.warning(results.mensagem);
                this.setState({results: null});
            }
        }
    }

    result = () => {
        if (this.state.results) {
            return (
                <Card>
                    <Tabs defaultActiveKey="info">
                        <Tab eventKey="info" title="INFORMAÇÕES">
                            <h5 className="mb-3 mt-4">Sintese cadastral:</h5>
                            <Info result={this.state.results}/>
                        </Tab>

                        <Tab eventKey="registros" title="DÉBITOS">
                            <h5 className="mb-3 mt-4">Débitos:</h5>
                            <Debitos result={this.state.results}/>
                        </Tab>

                        <Tab eventKey="consultas" title="CONSULTAS">
                            <h5 className="mb-3 mt-4">Consultas anteriores SCPC:</h5>
                            <Consultas result={this.state.results}/>
                        </Tab>
                    </Tabs>
                </Card>
            )
        } else {
            return '';
        }
    }

    onInputMask = () => {
        if (this.state.tipo_pessoa === 'F') {
            this.setState({mask: '999.999.999-99'})
        } else {
            this.setState({mask: '99.999.999/9999-99'})
        }
    }

    render() {
        const Results = () => this.result();

        return (
            <Page title="Pendencia Financeira SCPC + Protesto Estadual">
                <Card load={this.state.load}>
                    <form onSubmit={this.onSearch}>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <div className="form-group">
                                    <label>Tipo pessoa:</label>
                                    <select
                                        name="tipo_pessoa"
                                        value={this.state.tipo_pessoa}
                                        className="form-control"
                                        onChange={this.bindState}
                                        required>
                                        {/*<option value="F">Pessoa Física</option>*/}
                                        <option value="J">Pessoa Jurídica</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-md-3">
                                <div className="form-group">
                                    <label>Documento:</label>
                                    <InputMask
                                        placeholder="Digite o documento:"
                                        name="documento"
                                        value={this.state.documento}
                                        className="form-control"
                                        onChange={this.bindState}
                                        mask={this.state.mask}
                                        required/>
                                </div>
                            </div>
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <label>UF:</label>
                                    <InputMask
                                        placeholder="UF:"
                                        name="uf"
                                        value={this.state.uf}
                                        className="form-control"
                                        onChange={this.bindState}
                                        mask="aa"
                                        required/>
                                </div>
                            </div>
                            <div className="col-12 col-md-4" style={{marginTop: '32px'}}>
                                <Button type="submit" variant="primary">
                                    <i className="fas fa-search"/> Pesquisar
                                </Button>
                            </div>
                        </div>
                    </form>
                </Card>

                <Results/>
            </Page>
        );
    }
}

export default PendenciaFinanceiraScpcProtestoEstadual;