import React from "react";
import AppService from "../../services/app-service";
import toastr from 'toastr'

export default class Login extends React.Component {

    state = {
        email: '',
        password: '',
        load: false
    }

    constructor() {
        super();
        this.appService = new AppService();
    }

    bindState = (event) => {
        const inputName = event.target.name;
        const value = event.target.value;
        this.setState({[inputName]: value});
    }

    login = async (event) => {
        event.preventDefault();
        this.setState({load: true})
        const response = await this.appService.login({email: this.state.email, password: this.state.password});
        this.setState({load: false})
        if (response.success) {
            toastr.success(response.msg)
            setTimeout(function () {
                window.location = '/'
            }, 500)
        } else {
            toastr.warning(response.msg)
        }
    }

    btnLogin = () => {
        return !this.state.load ? (
            <button type="submit" className="btn btn-primary btn-block">
                <i className="fas fa-sign-in-alt"/> Entrar
            </button>
        ) : (
            <button type="submit" className="btn btn-primary btn-block" disabled={true}>
                <i className="fa fa-spinner fa-spin"/> Entrar
            </button>
        )
    }

    render() {
        const BtnLogin = () => this.btnLogin();

        return (
            <div className="card-body login-card-body">
                <p className="login-box-msg"><i className="fas fa-user-lock"/> Login</p>

                <form onSubmit={this.login}>
                    <div className="input-group mb-3">
                        <input
                            name="email"
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            onChange={this.bindState}/>
                        <div className="input-group-append">
                            <div className="input-group-text">
                                <span className="fas fa-envelope"/>
                            </div>
                        </div>
                    </div>
                    <div className="input-group mb-3">
                        <input
                            name="password"
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            onChange={this.bindState}/>
                        <div className="input-group-append">
                            <div className="input-group-text">
                                <span className="fas fa-lock"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8"/>

                        <div className="col-4">
                            <BtnLogin/>
                        </div>

                    </div>
                </form>


                {/*<p className="mb-1">*/}
                {/*    <a href="forgot-password.html">I forgot my password</a>*/}
                {/*</p>*/}
            </div>
        )
    }
}