import React from "react";
import AppService from "../../services/app-service";
import {useHistory} from "react-router-dom";

export default function NavTop() {

    const history = useHistory();

    function logout() {
        const appService = new AppService();
        appService.logout();
        history.push('/login')
    }

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">

            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="#!" role="button"><i
                        className="fas fa-bars"/></a>
                </li>
            </ul>


            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <a className="nav-link" href="#!" style={{cursor: 'pointer'}}>
                        <i className="fas fa-user"/> Meus Dados
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#!" onClick={logout} style={{cursor: 'pointer'}}>
                        <i className="fas fa-sign-out-alt"/> Logout
                    </a>
                </li>
            </ul>
        </nav>
    )
}