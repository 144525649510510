import React from "react";

function Info(props) {
    return (
        <>
            <div className="row mb-3">
                <div className="col-12 col-md-4">
                    <b>Nome:</b> {props.result.sintese_cadastral.nome}
                </div>
                <div className="col-12 col-md-4">
                    <b>CPF:</b> {props.result.sintese_cadastral.cpf}
                </div>
                <div className="col-12 col-md-4">
                    <b>Nome da mãe:</b> {props.result.sintese_cadastral.nome_mae}
                </div>
                <div className="col-12 col-md-4">
                    <b>Nascimento:</b> {props.result.sintese_cadastral.nascimento}
                </div>
            </div>
            <hr/>

            <h5 className="mb-3">Alerta documento SCPC:</h5>
            <div className="row mb-3">
                <div className="col-12 col-md-3">
                    <b>Quantidade:</b> {props.result.resumo_ocorrencias.alerta_documento_scpc.quantidade}
                </div>
                <div className="col-12 col-md-3">
                    <b>Última ocorrência:</b> {props.result.resumo_ocorrencias.alerta_documento_scpc.ultima_ocorrencia}
                </div>
                <div className="col-12 col-md-3">
                    <b>Valor:</b> {props.result.resumo_ocorrencias.alerta_documento_scpc.valor}
                </div>
            </div>
            <hr/>

            <h5 className="mb-3">Consultas anteriores SCPC:</h5>
            <div className="row mb-3">
                <div className="col-12 col-md-3">
                    <b>Quantidade:</b> {props.result.resumo_ocorrencias.consultas_anteriores_scpc.quantidade}
                </div>
                <div className="col-12 col-md-3">
                    <b>Última
                        ocorrência:</b> {props.result.resumo_ocorrencias.consultas_anteriores_scpc.ultima_ocorrencia}
                </div>
                <div className="col-12 col-md-3">
                    <b>Valor:</b> {props.result.resumo_ocorrencias.consultas_anteriores_scpc.valor}
                </div>
            </div>
            <hr/>

            <h5 className="mb-3">Registro SCPC BVS:</h5>
            <div className="row mb-3">
                <div className="col-12 col-md-3">
                    <b>Quantidade:</b> {props.result.resumo_ocorrencias.registro_scpc_bvs.quantidade}
                </div>
                <div className="col-12 col-md-3">
                    <b>Última ocorrência:</b> {props.result.resumo_ocorrencias.registro_scpc_bvs.ultima_ocorrencia}
                </div>
                <div className="col-12 col-md-3">
                    <b>Valor:</b> {props.result.resumo_ocorrencias.registro_scpc_bvs.valor}
                </div>
            </div>
        </>
    );
}

export default Info;