import React from "react";
import {Switch, Route, useHistory} from 'react-router-dom';
import AppService from "./services/app-service";

import Admin from "./layout/admin";
import Auth from "./layout/auth";
import Login from "./pages/auth/login";
import Home from "./pages/home/home";
import PendenciaFinanceiraScpc from "./pages/pendencia-financeira-scpc/pendencia-financeira-scpc";
import PendenciaFinanceiraScpcProtestoEstadual
    from "./pages/pendencia-financeira-scpc-protesto-estadual/pendencia-financeira-scpc-protesto-estadual";
import LideryPlaca from "./pages/lidery-placa";

const AppRoute = ({component: Component, layout: Layout, ...rest}) => {

    const history = useHistory();
    const appService = new AppService();

    if (Layout === Auth) {
        document.querySelector('body').classList.remove('sidebar-mini');
        document.querySelector('body').classList.add('login-page');
    } else {
        document.querySelector('body').classList.add('sidebar-mini');
        document.querySelector('body').classList.remove('login-page');

        if (!appService.isAuthenticate()) {
            history.push('/login');
        }
    }

    return (
        <Route {...rest} render={props => (
            <Layout><Component {...props} /></Layout>
        )}/>
    )
}

function Routes() {
    return (
        <Switch>
            <AppRoute exact path="/login" component={Login} layout={Auth}/>
            <AppRoute exact path="/" component={Home} layout={Admin}/>
            <AppRoute exact path="/grupovec/pendencia-financeira-scpc" component={PendenciaFinanceiraScpc} layout={Admin}/>
            <AppRoute exact path="/grupovec/pendencia-financeira-scpc-protesto-estadual" component={PendenciaFinanceiraScpcProtestoEstadual} layout={Admin}/>
            <AppRoute exact path="/lidery/placa" component={LideryPlaca} layout={Admin}/>
        </Switch>
    )
}

export default Routes;