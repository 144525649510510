import React from "react";
import NavTop from "./navbar/nav-top";
import Navigation from "./navbar/navigation";

export default function Admin(props) {

    return (
        <>
            <div className="wrapper">
                <NavTop/>
                <Navigation/>

                {props.children}

                <footer className="main-footer">
                    <div className="float-right d-none d-sm-inline">
                        {/*Qualquer coisa aqui*/}
                    </div>
                    <strong> Copyright &copy; </strong> 2020 Seraph. Todos os direitos reservados.
                </footer>
            </div>
        </>
    )
}