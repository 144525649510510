const dataMenu = [
    {nome: 'Home', path: '/', icon: 'fa-home', chave: 'home'},
    {
        nome: 'Pendências financeira',
        path: '#!',
        icon: 'fa-search',
        chave: 'spc_pessoa_fisica,spc_pessoa_juridica',
        children: [
            {
                nome: 'Pessoa física',
                path: '/grupovec/pendencia-financeira-scpc',
                chave: 'spc_pessoa_fisica',
                icon: null
            },
            {
                nome: 'Pessoa Jurídica',
                path: '/grupovec/pendencia-financeira-scpc-protesto-estadual',
                chave: 'spc_pessoa_juridica',
                icon: null
            },
        ]
    },
    {
        nome: 'Lidery', path: '#!', icon: 'fa-building', chave: 'pesquisa_placa_lidery', children: [
            {nome: 'Placa', path: '/lidery/placa', icon: null, chave: 'pesquisa_placa_lidery'},
        ]
    },
]

export default dataMenu;