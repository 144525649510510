import React from "react";
import {Link, useLocation} from "react-router-dom";
import AppService from "../../services/app-service";
import dataMenu from "./data-menu";

export default function Navigation() {

    let nomeUsuario = '';
    const appService = new AppService();
    const user = appService.getUser();
    if (user) {
        nomeUsuario = user.nome
    }

    const location = useLocation();
    const routerPath = location.pathname;

    const itemActive = (path) => {
        return path === routerPath ? 'active' : '';
    }

    const groupActive = (contains) => {
        return routerPath.includes(contains) ? 'active' : '';
    }

    const groupOpen = (contains) => {
        return routerPath.includes(contains) ? 'menu-open' : '';
    }

    const styleOpenMenu = (contains) => {
        return routerPath.includes(contains) ? {display: 'block'} : {display: 'none'};
    }

    const menuAcl = (chave) => {
        let acl = false;
        user.funcoes.forEach(funcao => {
            if (!acl) {
                acl = chave.indexOf(funcao.chave) !== -1;
            }
        });

        return acl;
    }

    const submenu = (children) => {
        const item = children.map(child => {
            if (menuAcl(child.chave)) {
                return (
                    <li className="nav-item" key={child.path}>
                        <Link to={child.path}
                              className={'nav-link ' + itemActive(child.path)}>
                            <i className="far fa-circle nav-icon"/>
                            <p>{child.nome}</p>
                        </Link>
                    </li>
                )
            } else {
                return '';
            }
        });

        return item;
    }

    const Menu = () => {
        const menu = dataMenu.map(menu => {
            if (menuAcl(menu.chave)) {
                return !menu.hasOwnProperty('children') ? (
                    <li className="nav-item" key={menu.path}>
                        <Link to={menu.path} className={'nav-link ' + itemActive(menu.path)}>
                            <i className={`nav-icon fas ${menu.icon}`}/>
                            <p>{menu.nome}</p>
                        </Link>
                    </li>
                ) : (
                    <li className={'nav-item has-treeview ' + groupOpen(menu.children[0].path.split('/')[1])}
                        key={`grupo-${menu.children[0].path}`}>
                        <a href="#!" className={'nav-link ' + groupActive(menu.children[0].path.split('/')[1])}>
                            <i className="nav-icon fas fa-search"/>
                            <p>
                                {menu.nome}
                                <i className="right fas fa-angle-left"/>
                            </p>
                        </a>
                        <ul className="nav nav-treeview" style={styleOpenMenu(menu.children[0].path.split('/')[1])}>
                            {submenu(menu.children)}
                        </ul>
                    </li>
                )
            } else return '';
        });
        return menu;
    }

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <Link to="/" className="brand-link">
                <span className="brand-text font-weight-light">Sistema Pesquisa</span>
            </Link>

            <div className="sidebar">

                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="info">
                        <a href="#!" className="d-block">
                            <i className="fas fa-user-circle"/> {nomeUsuario}
                        </a>
                    </div>
                </div>

                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">

                        <Menu/>
                    </ul>
                </nav>

            </div>
        </aside>
    )
}